<template>
  <div class="cooperation-container">
    <div class="cooperation-header">
      <div class="cooperation-header-title">
        <img
          src="@/assets/cooperation/cooperationLeftIcon.png"
          alt="left"
          class="title-icon"
        />
        <div>柚子慧合作伙伴申请流程</div>
        <img
          src="@/assets/cooperation/cooperationRightIcon.png"
          alt="right"
          class="title-icon"
        />
      </div>
      <div class="step-container">
        <div class="progress-bar">
          <div class="dashed-line"></div>
          <div class="drop active">
            <img
              src="@/assets/cooperation/cooperationIcon1.png"
              alt="提交申请"
              class="progress-img"
            />
            <div class="progress-title">提交申请</div>
          </div>
          <div class="dashed-line"></div>
          <div class="drop">
            <img
              src="@/assets/cooperation/cooperationIcon2.png"
              alt="申请资料"
              class="progress-img"
            />
            <div class="progress-title">申请资料</div>
          </div>
          <div class="dashed-line"></div>
          <div class="drop">
            <img
              src="@/assets/cooperation/cooperationIcon3.png"
              alt="洽谈合作"
              class="progress-img"
            />
            <div class="progress-title">洽谈合作</div>
          </div>
          <div class="dashed-line"></div>
          <div class="drop">
            <img
              src="@/assets/cooperation/cooperationIcon4.png"
              alt="签约入驻"
              class="progress-img"
            />
            <div class="progress-title">签约入驻</div>
          </div>
          <div class="dashed-line"></div>
        </div>
      </div>
    </div>
    <div class="cooperation-bottom"></div>
    <div class="cooperation-info">
      <div class="cooperation-form-title">商务合作申请</div>
      <!-- 表单 -->
      <div class="cooperation-form">
        <a-form
          :model="formState"
          :rules="rules"
          size="large"
          ref="formRef"
          @finish="onFinish"
        >
          <div class="agent-form">
            <div class="row-form">
              <a-form-item name="shopName" class="agent-item-shopName">
                <div class="agent-label">
                  <span> 店铺名称 <span class="required"> *</span></span>
                </div>
                <a-input
                  v-model:value="formState.shopName"
                  placeholder="请输入店铺名称"
                  :disabled="disabled"
                  class="agent-input"
                />
              </a-form-item>

              <!-- <a-form-item
                name="areaIds"
                class="agent-item"
              >
                <div class="agent-label">
                  <span><span class="required">*</span>所在区域</span>
                </div>
                <TreeSelect
                  :fieldNames="{ label: 'name', value: 'id' }"
                  :tree-data="areatreeData"
                  :disabled="disabled"
                  :value="formState.areaIds"
                  :showSearch="true"
                  :multiple="true"
                  :allowClear="true"
                  treeNodeFilterProp="name"
                  placeholder="请选择所在区域"
                  class="agent-input"
                  @change="
                    (value, label) => {
                      changeAreaIds(value);
                    }
                  "
                  v-if="reload"
                >
                </TreeSelect>
              </a-form-item> -->
            </div>
            <div class="row-form">
              <a-form-item name="industry" class="agent-item">
                <div class="agent-label">
                  <span>行业 <span class="required"> *</span></span>
                </div>
                <a-select
                  v-model:value="formState.industry"
                  placeholder="请选择行业"
                  :disabled="disabled"
                  :options="industryList"
                  :fieldNames="{ label: 'label', value: 'value' }"
                  :allowClear="true"
                ></a-select>
                <!-- <a-input
                  v-model:value="formState.industry"
                  placeholder="请选择行业"
                  :disabled="disabled"
                  class="agent-input"
                /> -->
              </a-form-item>
              <a-form-item name="cityId" class="agent-item">
                <div class="agent-label">
                  <span>所在区域 <span class="required"> *</span></span>
                </div>
                <a-tree-select
                  v-model:value="formState.cityId"
                  show-search
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :fieldNames="{ label: 'name', value: 'id' }"
                  placeholder="请选择所在区域"
                  allow-clear
                  :tree-data="areatreeData"
                  tree-node-filter-prop="label"
                >
                </a-tree-select>
              </a-form-item>
            </div>
            <div class="row-form">
              <a-form-item name="contactUser" class="agent-item">
                <div class="agent-label">
                  <span>联系人 <span class="required"> *</span></span>
                </div>
                <a-input
                  v-model:value="formState.contactUser"
                  placeholder="请输入联系人"
                  :disabled="disabled"
                  class="agent-input"
                />
              </a-form-item>
              <a-form-item name="address" class="agent-item">
                <div class="agent-label">
                  <span>详细地址 <span class="required"> *</span></span>
                </div>
                <a-input
                  v-model:value="formState.address"
                  placeholder="请输入详细地址"
                  :disabled="disabled"
                  class="agent-input"
                />
              </a-form-item>
            </div>
            <div class="row-form">
              <a-form-item name="contactPhone" class="agent-item">
                <div class="agent-label">
                  <span>联系电话 <span class="required">*</span></span>
                </div>
                <a-input
                  v-model:value="formState.contactPhone"
                  placeholder="请输入联系电话"
                  maxLength="20"
                  :disabled="disabled"
                  class="agent-input"
                />
              </a-form-item>
              <a-form-item
                name="verificationCode"
                class="agent-item agent-item-code"
              >
                <div class="agent-label">
                  <span>验证码 <span class="required"> *</span></span>
                </div>
                <a-input
                  v-model:value="formState.verificationCode"
                  placeholder="请输入验证码"
                  maxLength="4"
                  :disabled="disabled"
                  class="agent-input"
                >
                  <template #suffix>
                    <span v-if="codeState.isCountDown" class="code-btn"
                      >{{ codeState.countdown }}秒</span
                    >
                    <span
                      v-else
                      @click="getVerificationCode"
                      class="code-btn cursor"
                      >发送验证码</span
                    >
                  </template>
                </a-input>
              </a-form-item>
            </div>

            <!-- <a-form-item name="address"> -->
            <a-checkbox v-model:checked="formState.checked" size="large">
              <span class="checkbox-text"
                >柚子慧可以使用我的联系信息随时通知我有关服务和产品的信息</span
              ></a-checkbox
            >
            <!-- </a-form-item> -->
          </div>
          <div>
            <a-button
              type="primary"
              html-type="submit"
              class="submit-btn"
              :loading="loading"
              >提交申请</a-button
            >
          </div>
        </a-form>
        <!-- 提交成功提示弹窗 -->
        <SuccessModal @closeModel="closeModel" v-if="visible" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, nextTick, onMounted } from "vue";
import { message } from "ant-design-vue";
import type { Rule } from "ant-design-vue/es/form";
import type { TreeSelectProps } from "ant-design-vue";
import SuccessModal from "./successModal.vue";
import {
  getDictType,
  getAreaTree,
  sendCodeApi,
  createMerchantApply,
  SendCode,
  MerchantApplyParams,
} from "@/api/index";
const formState = reactive<MerchantApplyParams>({
  shopName: "", //店铺名称
  industry: undefined, //行业
  cityId: undefined, //城市ID
  contactUser: "", //联系人
  address: "", //详细地址
  contactPhone: "", //联系电话
  verificationCode: "", //验证码
  requestId: "", //请求唯一id
  checked: false,
});
interface State {
  /**倒计时 */
  countdown: number;
  /**是否是倒计时 */
  isCountDown: boolean;
}
//手机验证码交互变量
const codeState = reactive<State>({
  countdown: 0,
  isCountDown: false,
});
const loading = ref(false);
const visible = ref(false);
const industryList = ref([]);
const formRef = ref();
const areatreeData = ref<TreeSelectProps["treeData"]>([]);
/**计算倒计时 */
const computedCountDown = () => {
  const interval = setInterval(() => {
    /**倒计时结束 */
    if (codeState.countdown === 1) {
      codeState.isCountDown = false;
      clearInterval(interval);
      return;
    }
    codeState.countdown -= 1;
  }, 1000);
};
let MobileReg =
  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
const validatePhone = async (_rule: Rule, value: string) => {
  if (MobileReg.test(value)) return Promise.resolve();
  else if (!value) return Promise.resolve();
  else return Promise.reject("手机号格式不正确，请重新输入");
};
const rules = reactive({
  shopName: [{ required: true, message: "请输入店铺名称" }],
  industry: [{ required: true, message: "请选择行业" }],
  cityId: [{ required: true, message: "请选择所在区域" }],
  contactUser: [{ required: true, message: "请输入联系人" }],
  contactPhone: [
    { required: true, message: "请输入联系电话" },
    { trigger: "blur", validator: validatePhone },
  ],
  verificationCode: [{ required: true, message: "请输入验证码" }],
  address: [{ required: true, message: "请输入详细地址" }],
});

onMounted(async () => {
  const res = await getDictType("agent_industry");
  if (res.data.code === 200) {
    industryList.value = res.data.data;
  }
  const resTree = await getAreaTree();
  if (resTree.data.code === 200) {
    areatreeData.value = resTree.data.data;
    areatreeData.value.map((item: any) => {
      if (item.children?.length) item.disabled = true;
    });
  }
});
/**获取验证码 */
const getVerificationCode = async () => {
  if (!formState.contactPhone) {
    return message.error("请输入手机号");
  }
  if (formState.contactPhone && !MobileReg.test(formState.contactPhone))
    return message.error("请输入正确格式的手机号");
  if (codeState.countdown > 1) return;
  formState.requestId = Math.floor(Math.random() * 100000000);
  const params = {
    requestId: formState.requestId,
    mobile: formState.contactPhone,
  };
  await sendCodeApi(params as SendCode);
  codeState.isCountDown = true;
  codeState.countdown = 59;
  computedCountDown();
};
const onFinish = async (values: any) => {
  let data = JSON.parse(JSON.stringify(formState));
  delete data.checked;
  console.log(data, "data");
  loading.value = true;
  setTimeout(() => (loading.value = false), 3000);
  const res: any = await createMerchantApply(data as MerchantApplyParams);
  if (res.data.code === 200) {
    visible.value = true;
    loading.value = false;
  } else {
    message.error(res?.data?.message);
  }
};
const closeModel = () => {
  visible.value = false;
  formRef.value.resetFields();
  formState.checked = false;
  formState.requestId = "";
  console.log(formState, "formState");
};
</script>

<style lang="less" scoped>
.cooperation-header {
  width: 100%;
  height: 440px;
  background-image: url("@/assets/cooperation/cooperationBg1.png");
  background-size: 100% 100%;
  padding-top: 60px;
  .cooperation-header-title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .title-icon {
      width: 60px;
      height: 42px;
    }
    div {
      font-size: 36px;
      font-weight: bold;
      background: linear-gradient(180deg, #ffffff 30%, #fff2a1 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin: 0 4px;
    }
  }
}
.step-container {
  padding: 120px 0 50px;
  height: 164px;
  width: 56%;
  margin: 48px auto 0;
  .progress-bar {
    width: 100%;
    display: flex;
    align-items: center;
    .dashed-line {
      width: 24%;
      border-top: 1px dashed #ffffff;
      &:first-child {
        width: 14%;
      }
      &:last-child {
        width: 14%;
      }
    }
    .drop {
      position: relative;
      width: 10px;
      height: 10px;
      background: #ffffff;
      border-radius: 50%;
      .progress-img {
        position: absolute;
        top: -122px;
        left: -42px;
        width: 100px;
        height: 100px;
      }
      .progress-title {
        width: 64px;
        position: absolute;
        bottom: -40px;
        left: -27px;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
}
.cooperation-bottom {
  height: 835px;
  background-image: url("@/assets/cooperation/cooperationBg2.png");
  background-size: 100% 100%;
}
.cooperation-container {
  position: relative;
  .cooperation-info {
    position: absolute;
    top: 384px;
    left: calc(50% - 31%);
    width: var(--main-width);
    padding: 28px 50px 50px;
    // height: 800px;
    border-radius: 8px;
    background: linear-gradient(180deg, #ffffff 0%, #fffaf6 100%);
    .cooperation-form-title {
      font-size: 40px;
      line-height: 52px;
      color: #181818;
      margin-bottom: 40px;
    }
  }
}
.cooperation-form {
  // overflow-y: auto;
  .title {
    font-size: 15px;
    font-weight: 550;
    margin: 0px 0px 10px 0px;
  }
  .row-form {
    display: flex;
    justify-content: space-between;
  }
  .agent-item-shopName {
    width: 100%;
  }
  .agent-item {
    width: 48%;
    // margin-right: 30px;
  }
  :deep(.ant-form-item-explain-error) {
    font-size: 18px;
  }
  :deep(.ant-input) {
    height: 60px !important;
    font-size: 18px;
  }
  :deep(.agent-item-code .ant-input) {
    height: 42px !important;
    font-size: 18px;
  }
  :deep(.ant-select-selector) {
    height: 60px !important;
  }
  :deep(.ant-select-selection-item) {
    display: flex;
    align-items: center;
  }
  :deep(.ant-select-selector) {
    display: flex;
    align-items: center;
  }
  :deep(.ant-select-selection-search) {
    display: flex;
    align-items: center;
  }
  :deep(.ant-checkbox-inner) {
    display: inline-block;
    width: 30px;
    height: 30px;
  }
  :deep(.ant-checkbox .ant-checkbox-inner:after) {
    top: 45%;
    width: 8.714286px;
    height: 18.142857px;
  }
  .agent-label {
    width: 270px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.36px;
    color: rgba(0, 0, 0, 0.8);
    .required {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 18px;
    }
  }
  .agent-input {
    // width: 350px;
  }
  .checkbox-text {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.36px;
    color: rgba(0, 0, 0, 0.8);
  }
  .submit-btn {
    width: 400px;
    height: 64px;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    margin: 40px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0px;
    color: #ffffff;
    background: #ea4f13;
    cursor: pointer;
  }
  :deep(.ant-form-item) {
    margin-bottom: 20px;
  }
}
.code-btn {
  display: inline-block;
  width: 100px;
  height: 38px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  background: #ff4f12;
}
@media screen and (max-width: 1100px) {
  .cooperation-info {
    left: calc(50% - 39%) !important;
  }
}
</style>