<template>
  <div class="about-us-container">
    <div class="about-us-header flex-center">
      <div>赋予研学增长动能</div>
    </div>
    <!-- 柚子慧简介 -->
    <div class="about-us-intro-container">
      <div class="about-us-intro">
        <div class="intro-info">
          <div class="title">柚子慧简介</div>
          <div class="content">
            柚子慧一家以研学产品与用户服务为核心的技术服务商，创始至今已服务逾百万家客户，其中不乏各行业一线知名品牌。
            现如今，私域运营正在逐渐成为数字化经营的重要手段，并助推企业的业务升级和组织建设升级。柚子慧作为私域运营的一站式工具，解决产品和服务交付、营销获客、用户运营、组织角色管理、品牌价值输出等痛点并形成闭环，扎根研学行业与生态，可在企业经营过程中发挥重要作用，成为企业数字化经营的好帮手。
          </div>
        </div>
        <img src="@/assets/common/logoNoBg.svg" alt="logo" class="logo" />
      </div>
    </div>
    <!-- 品牌故事 -->
    <div class="story-container">
      <div class="story-intro">
        <div class="intro-info">
          <div class="title">品牌故事</div>
          <!-- 进度条 -->
          <div class="step-container">
            <div class="progress-bar">
              <div class="dashed-line"></div>
              <div class="drop active">
                <div class="progress-title">2024</div>
              </div>
              <div class="dashed-line"></div>
              <div class="drop">
                <div class="progress-title">2023</div>
              </div>
              <div class="dashed-line"></div>
              <div class="drop">
                <div class="progress-title">2022</div>
              </div>
              <div class="dashed-line"></div>
              <div class="drop">
                <div class="progress-title">2021</div>
              </div>
              <div class="dashed-line"></div>
            </div>
          </div>
          <div class="content-box">
            <div style="width: 40%">
              <div class="year">2024年</div>
              <div class="intro-detail-item">
                <div class="month">10月</div>
                <div>上海、深圳本地商家陪跑营开跑，研学数字赋能、 线下沙龙</div>
              </div>
              <div class="intro-detail-item">
                <div class="month">9月</div>
                <div>
                  上联合艾瑞咨询发布《2023年中国私域运
                  营洞察白皮书》，通过建立公域引流、内容运营、营销转化、用户运营、交易变现的全链路闭环，企业可以实现公域与私域的联动运营，
                  提升企业与用户的互信，延长用户生命周期
                </div>
              </div>
              <div class="intro-detail-item">
                <div class="month">8月</div>
                <div>
                  推出行业数字化教练联盟，汇集研学各行业标杆，以服务为宗旨赋能商家，提供运营方法论，助力商家数字化转型
                </div>
              </div>
            </div>
            <div style="width: 40%; margin-top: 26px">
              <div class="intro-detail-item">
                <div class="month">5月</div>
                <div>
                  训练营再升级，行业售前训练营，通过行业大咖分享、社群式学习交流，实现行业精英深度交流共创，共建行业数字化生态
                </div>
              </div>
              <div class="intro-detail-item">
                <div class="month">3月</div>
                <div>
                  “柚子畅谈会"重磅开播，线下沙龙会议探讨知识服务在行业中的发展趋势，分享行业经验与见解
                </div>
              </div>
              <div class="intro-detail-item">
                <div class="month">1月</div>
                <div>
                  荣获《优质战略合作伙伴》，打造商家和柚子慧产品负责人的互动桥梁，在线聆听每位商家老师的产品需求
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 立即申请 -->
    <div class="apply-container">
      <div>
        <div class="apply-title">柚子慧，让您一分钟拥有自己的游学店铺</div>
        <div class="apply-btn flex-center" @click="apply">立即申请</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, nextTick } from "vue";
const emit = defineEmits(["changeAcitve"]);
const apply = () => {
  emit("changeAcitve", 3);
};
</script>

<style lang="less" scoped>
.about-us-header {
  width: 100%;
  height: 260px;
  background-image: url("@/assets/aboutUs/aboutBg1.png");
  background-size: 100% 100%;
  div {
    font-size: 56px;
    font-weight: bold;
    line-height: 80px;
    color: #ffffff;
    text-shadow: 0px 1px 1px #cb4213;
  }
}
.about-us-intro-container {
  background-image: url("@/assets/aboutUs/aboutBg3.png");
  background-size: 100% 100%;
}
.title {
  font-size: 36px;
  font-weight: 500;
  line-height: 60px;
  color: #1d2129;
  margin-bottom: 32px;
}
.about-us-intro {
  // height: 540px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: var(--main-width);
  padding: 60px 60px 40px 60px;
  .intro-info {
    margin-right: 120px;
    .title {
      margin-bottom: 32px;
    }
    .content {
      font-size: 16px;
      font-weight: 300;
      line-height: 36px;
      color: #181818;
    }
  }
  .logo {
    width: 260px;
    height: 169px;
  }
}
.story-container {
  // height: 648px;
  background: #f5f8fd;
  padding: 60px;
  .story-intro {
    width: var(--main-width);
    margin: auto;
    .title {
      margin-bottom: 70px;
      font-size: 36px;
      font-weight: bold;
      line-height: 60px;
      color: #1d2129;
      text-align: center;
    }
  }
  .content-box {
    width: 80%;
    margin: 12px auto 0;
    display: flex;
    justify-content: space-between;
    .intro-detail-item {
      margin-top: 20px;
    }
    .year {
      height: 26px;
      font-size: 18px;
      font-weight: bold;
      color: #181818;
      cursor: pointer;
    }
    .month {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #181818;
      margin-bottom: 4px;
    }
  }
}
.step-container {
  .progress-bar {
    width: 100%;
    display: flex;
    align-items: center;
    .dashed-line {
      width: 23%;
      border-top: 1px dashed rgba(255, 79, 18, 0.5);
      &:first-child {
        width: 17%;
      }
      &:last-child {
        width: 17%;
      }
    }
    .drop {
      position: relative;
      width: 10px;
      height: 10px;
      background: #ff4f12;
      border-radius: 50%;
      cursor: pointer;
      &.active {
        .progress-title {
          top: -40px;
          left: -20px;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          color: #ff4f12;
          cursor: pointer;
        }
      }
      .progress-title {
        position: absolute;
        top: -30px;
        left: -10px;
        width: 23%;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        color: #181818;
      }
    }
  }
}
.apply-container {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("@/assets/aboutUs/aboutBg2.png");
  background-size: 100% 100%;
  text-align: center;
  .apply-title {
    font-size: 40px;
    font-weight: normal;
    line-height: 60px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .apply-btn {
    width: 247px;
    height: 44px;
    border-radius: 202px;
    border: 1px solid #ffffff;
    border: 1px solid #fff;
    margin: auto;
    font-weight: 350;
    line-height: 22px;
    color: #ffffff;
    cursor: pointer;
  }
}
@media screen and (max-width: 1350px) {
  .intro-info {
    margin-right: 50px !important;
  }
}
</style>