<template>
  <div class="app-contaniner">
    <Header @changePage="changePage" :newActiveIndex="activeIndex"/>
    <div class="main-container-out">
      <div class="main-container">
        <Home v-if="activeIndex === 0" @changeAcitve="changeAcitve" />
        <Solution v-if="activeIndex === 1" />
        <Package v-if="activeIndex === 2" @changeAcitve="changeAcitve" />
        <Cooperation v-if="activeIndex === 3" />
        <AboutUs v-if="activeIndex === 4" @changeAcitve="changeAcitve" />
        <div class="float-container" v-if="floatVisible && activeIndex === 0">
          <img
            src="@/assets/common/closeIcon.png"
            alt="关闭浮窗"
            class="close-icon"
            @click="floatVisible = false"
          />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Home from "@/views/home/index.vue";
import Solution from "@/views/solution/index.vue";
import Package from "@/views/package/index.vue";
import Cooperation from "@/views/cooperation/index.vue";
import AboutUs from "@/views/aboutUs/index.vue";
const activeIndex = ref(0);
const floatVisible = ref(true);
const changePage = (index) => {
  activeIndex.value = index;
  const outEle = document.getElementsByClassName("main-container-out");
  outEle[0].scrollTop = 0;
};
const changeAcitve = (active) => {
  activeIndex.value = active;
  const outEle = document.getElementsByClassName("main-container-out");
  outEle[0].scrollTop = 0;
};
</script>
<style lang="less" scoped>
.app-contaniner {
  width: 100%;
  height: 100vh;
}
.main-container-out {
  overflow-y: auto;
  height: calc(100% - 90px);
}
.main-container {
  position: relative;
  width: 100%;
  min-height: 200px;
  overflow-y: auto;
  .float-container {
    position: fixed;
    top: calc(50% - 168px);
    right: 35px;
    width: 124px;
    height: 336px;
    border-radius: 12px;
    background-image: url("@/assets/common/floatBg.png");
    background-size: 100%;
    .close-icon {
      position: absolute;
      top: -9px;
      right: -12px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
</style>


