import axios from 'axios'

axios.defaults.timeout = 50000
/**
 * 获取上海时区的时间戳
 */
 function getShanghaiZoneTime() {
    const now = new Date();
    now.setUTCHours(now.getUTCHours() + 8);
    return now.getTime()
  }
/**
 * 获取上海时区的时间戳，然后第10位插入6
 */
function convertShanghaiSring() {
    const nowSring = getShanghaiZoneTime() + ''
    const convertSring = nowSring.slice(0, 9) + '6' + nowSring.slice(9)
    return convertSring
  }
// 创建 axios 实例
const service = axios.create({
    baseURL: import.meta.env.VITE_GLOB_API_URL,
    timeout: 50000,
    headers: { "Content-Type": "application/json;charset=utf-8", 'fetch-key': convertShanghaiSring(), },
});

axios.interceptors.request.use(config => {
    // ...
    return config
}, error => {
    return Promise.error(error)
})



export default service;