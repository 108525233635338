import defHttp from '../requests/index.js';
/**
 * @description: 手机验证码登录
 * @param mobile 手机号
 * @param requestId 请求id（10位随机数）
 */
export interface SendCode {
    mobile: string | number
    requestId: number
}

/**
* @description: 验证码登录
* @param shopName 店铺名称
* @param industry 行业
* @param cityId 城市ID
* @param contactUser 联系人
* @param contactPhone 联系电话
* @param address 验证码
* @param verificationCode 验证码
* @param requestId 请求id（10位随机数）
*/
export interface MerchantApplyParams {
    shopName: string | number
    industry: string
    cityId: number
    contactUser: string
    contactPhone: string
    address: string
    verificationCode: number
    requestId: number
}

//获取行业
export function getDictType(params: string) {
    return defHttp.get(`/public/dict-data/list-select-simple?dictType=${params}`)
}
// 获得地区树
export function getAreaTree() {
    return defHttp.get('/public/area/tree')
}
/**
 * @description: 发送验证码
 */
export function sendCodeApi(params: SendCode) {
    return defHttp.post( '/public/merchant-apply/send/verification-code',params)
}
/**
* @description: 店铺申请
*/
export function createMerchantApply(params: MerchantApplyParams) {
    return defHttp.post('/public/merchant-apply/create',params)
}