 <template>
  <div class="content-box">
    <div class="banner">
      <img src="@/assets/home/homeImg.png" alt="banner" class="header-img" />
      <img
        src="@/assets/home/ruzhuBtn.png"
        alt="banner"
        class="banner-btn"
        @click="apply"
      />
    </div>
    <div class="product-container">
      <div class="title">柚子慧的产品优势是什么？</div>
      <div class="product-tabs">
        <div class="tab-item">
          <img
            src="@/assets/home/turn-around.png"
            alt="图标"
            class="tab-icon"
          />
          多维获客
        </div>
        <div class="tab-item">
          <img
            src="@/assets/home/zijinyunying.png"
            alt="图标"
            class="tab-icon"
          />裂变运营
        </div>
        <div class="tab-item">
          <img
            src="@/assets/home/trend-two.png"
            alt="图标"
            class="tab-icon"
          />智能分析
        </div>
        <div class="tab-item">
          <img
            src="@/assets/home/material-three.png"
            alt="图标"
            class="tab-icon"
          />轻量工具
        </div>
      </div>
      <!-- 快速开启产品变现模式 -->
      <div class="product-intro">
        <div class="intro-info ma-r-138">
          <div class="intro-title">快速开启产品变现模式</div>
          <div class="intro-desc">
            多个渠道整合，吸引潜在用户，从而将他们引流至私域流量池
          </div>
          <div class="segment"></div>
          <div class="content">
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                全渠道引流获客：打通公众号、视频号、快手、抖音等公域流量吸引潜在用户引流至私域流量池
              </div>
            </div>
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                内容引流：支持图文、音频、视频等内容形式转化私域加粉
              </div>
            </div>
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                互联网营销：活动发布、优惠券、产品推荐精选、团购模式、套餐模式、邀请奖励、研学视频、资讯等不同方式转化下单
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/home/intro1.png" alt="介绍" class="intro-img" />
      </div>
      <!-- 丰富的运营形式，带动高效转化 -->
      <div class="product-intro">
        <img src="@/assets/home/scene.png" alt="介绍" class="intro-img" />
        <div class="intro-info ma-l-138">
          <div class="intro-title">丰富的运营形式，带动高效转化</div>
          <div class="intro-desc">
            丰富的互动和营销玩法，加速消费决策，促进下单
          </div>
          <div class="segment"></div>
          <div class="content">
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                更智能化的产品库，分主题、类型、年龄段、时长进行，针对不同用户消费需求进行统一创建和管理
              </div>
            </div>
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                提高曝光度，同时帮助用户更好地了解产品信息，从而提高转化率
              </div>
            </div>
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                合理的订单管理，实时记录每笔订单在不同时段的状态，根据整个团对订单进行查看和管理，售后处理更便捷更人性化，降低人力成本
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 全链路数据打通，AI模型提升用户留存 -->
      <div class="product-intro">
        <div class="intro-info ma-r-138">
          <div class="intro-title">全链路数据打通，AI模型提升用户留存</div>
          <div class="intro-desc">AI数据模型了解用户在各个环节的行为</div>
          <div class="segment"></div>
          <div class="content">
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                访问用户、下单用户、下单率、支付率、转化率数据一目了然
                提升产品转化、优化运营模式等
              </div>
            </div>
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                用户全生命周期数据检测，全链路数据打通，精准洞察用户浏览和购买行为，紧抓用户习惯和购买类型，快速推出热门产品
              </div>
            </div>
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                实现数据化大屏，电脑端、移动端数据互通，掌上可随时查看数据变化情况，做到随时随地查看店铺情况
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/home/introImg2.png" alt="介绍" class="intro-img" />
      </div>
      <!-- 多样内容工具，提升用户体验 -->
      <div class="product-intro">
        <img src="@/assets/home/goGroup.png" alt="介绍" class="intro-img" />
        <div class="intro-info ma-l-138">
          <div class="intro-title">多样内容工具，提升用户体验</div>
          <div class="intro-desc">
            提升复购能力的关键是要提供优质的研学内容和服务
          </div>
          <div class="segment"></div>
          <div class="content">
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                提供多样化的内容工具，支持产品呈现、图文、视频、资讯、团购、套餐互动等内容类型
              </div>
            </div>
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                产品设计清晰易用、冗余的操作，提高用户的学习和使用效率
              </div>
            </div>
            <div class="content-item">
              <img
                src="@/assets/home/descIcon.png"
                alt="介绍文本"
                class="desc-icon"
              />
              <div class="text">
                支持多角色、多端口，满足用户在不同场景下操作诉求，无需下载即走即用的方式
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我们的技术优势 -->
    <div class="skill-container">
      <div class="title">我们的技术优势</div>
      <div class="img-box">
        <div class="skill-img-box">
          <div class="text-box">
            <div class="skill-title">超稳定</div>
            <div class="flex align-items-center">
              <img
                src="@/assets/common/successIcon.png"
                alt="success"
                class="success-icon"
              /><span class="skill-desc">多云负载均衡/全球CDN加速</span>
            </div>
          </div>
        </div>
        <div class="skill-img-box">
          <div class="text-box">
            <div class="skill-title">高并发</div>
            <div class="flex align-items-center">
              <img
                src="@/assets/common/successIcon.png"
                alt="success"
                class="success-icon"
              /><span class="skill-desc">支持多用户同时在线</span>
            </div>
          </div>
        </div>
        <div class="skill-img-box">
          <div class="text-box">
            <div class="skill-title">超流畅</div>
            <div class="flex align-items-center">
              <img
                src="@/assets/common/successIcon.png"
                alt="success"
                class="success-icon"
              /><span class="skill-desc"
                >自动探测并自动跨云<br />
                线路切换</span
              >
            </div>
          </div>
        </div>
        <!-- <img
          src="@/assets/home/homeImge3.png"
          alt="homeImge"
          class="home-img"
        />
        <img
          src="@/assets/home/homeImge4.png"
          alt="homeImge"
          class="home-img"
        /> -->
      </div>
    </div>
    <!-- 我们的增值服务 -->
    <div class="service-container">
      <div class="service-info">
        <div class="title">我们的增值服务</div>
        <div class="service-info-content">
          <img
            src="@/assets/home/forMe.png"
            alt="联系我们"
            class="call-me-img"
          />
          <div class="icon-container">
            <div class="icon-box">
              <div class="icon-item">
                <img src="@/assets/home/serviceIcon.png" alt="图标" />
                <div>专属服务</div>
              </div>
              <div class="icon-item">
                <img src="@/assets/home/newIcon.png" alt="新品开发" />
                <div>新品开发</div>
              </div>
              <div class="icon-item">
                <img src="@/assets/home/compued.png" alt="敏捷迭代" />
                <div>敏捷迭代</div>
              </div>
              <div class="icon-item">
                <img src="@/assets/home/support.png" alt="技术支持" />
                <div>技术支持</div>
              </div>
            </div>
            <div class="icon-box">
              <div class="icon-item"></div>
              <div class="icon-item">
                <img src="@/assets/home/reportIcon.png" alt="趋势报告" />
                <div>趋势报告</div>
              </div>
              <div class="icon-item">
                <img src="@/assets/home/resoureIcon.png" alt="行业资源" />
                <div>行业资源</div>
              </div>
              <div class="icon-item">
                <img src="@/assets/home/trainingIcon.png" alt="专家培训" />
                <div>专家培训</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 和柚子慧一起，探索研学增长动能 -->
    <ContactFooter />
    <!-- <div class="contact-container">
      <div class="contact-title">和柚子慧一起，探索研学增长动能</div>
      <div class="desc">欢迎联系柚子慧营销达人专家，顾问将为您提供全力支持</div>
      <img src="@/assets/home/contactBtn.png" alt="联系我们" />
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { ref, nextTick } from "vue";
import ContactFooter from "@/components/ContactFooter.vue";
const emit = defineEmits(["changeAcitve"]);
const apply = () => {
  emit("changeAcitve", 3);
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;
  .banner {
    position: relative;
    width: 100%;
    // height: 560px;
    // background-image: url("@/assets/home/homeImg.png");
    // background-size: 100% 100%;
    .header-img {
      width: 100%;
    }
    .banner-btn {
      position: absolute;
      left: 19%;
      top: 67%;
      width: 180px;
      height: 40px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  .product-container {
    width: var(--main-width);
    margin: auto;
  }
  .product-tabs {
    // width: 70%;
    width: 836px;
    height: 68px;
    margin: 0 auto 24px;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    padding: 20px 60px;
    background: #f7f8fa;
    .tab-item {
      font-size: 18px;
      line-height: 28px;
      color: #1d2129;
      cursor: pointer;
      .tab-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
  .product-intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
    .intro-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding: 45px 0;
      .intro-title {
        font-size: 36px;
        font-weight: bold;
        line-height: 60px;
        color: #1d2129;
        margin-bottom: 8px;
      }
      .intro-desc {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        color: #86909c;
      }
      .segment {
        width: 17px;
        height: 2px;
        margin: 20px 0;
        background: #d8d8d8;
      }
      .content-item {
        display: flex;
        align-items: center;
        margin-bottom: 28px;
        &:last-child {
          margin-bottom: 0px;
        }
        .desc-icon {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
        .text {
          font-size: 16px;
          line-height: 24px;
          color: #86909c;
        }
      }
    }
    .intro-img {
      width: 500px;
      height: 400px;
    }
  }
}
.service-container {
  width: 100%;
  height: 700%;
  background: #fff;
  .service-info {
    margin: auto;
    width: var(--main-width);
    .title {
      margin: 60px 0 24px;
    }
    .service-info-content {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      .call-me-img {
        width: 600px;
        height: 546px;
      }
      .icon-container {
        position: absolute;
        top: 79px;
        right: 0;
        .icon-box {
          display: flex;
          text-align: center;
          justify-content: end;
          .icon-item {
            width: var(--main-width);
            margin-right: 100px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
            img {
              width: 100px;
              height: 100px;
            }
            div {
              font-size: 18px;
              font-weight: normal;
              line-height: 34px;
              color: #181818;
              margin-top: 8px;
            }
          }
          &:first-child {
            margin-bottom: 60px;
          }
          // &:last-child {
          //   justify-content: end;
          // }
        }
      }
    }
  }
}
// .contact-container {
//   width: 100%;
//   height: 320px;
//   background: url("@/assets/home/contactBg.png");
//   background-size: 100% 100%;
//   text-align: center;
//   padding: 60px 0;
//   .contact-title {
//     font-size: 40px;
//     line-height: 60px;
//     color: #1d2129;
//   }
//   .desc {
//     font-size: 20px;
//     line-height: 24px;
//     color: #86909c;
//     margin: 8px 0 48px;
//   }
//   img {
//     width: 240px;
//     height: 60px;
//     border-radius: 8px;
//     cursor: pointer;
//   }
// }
.title {
  font-size: 36px;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
  margin: 40px auto 24px;
  color: #1d2129;
}
.home-img {
  width: 100%;
  margin: auto;
}
.skill-container {
  width: 100%;
  background: #f5f7fa;
  padding: 60px 0;
  .title {
    margin-top: 0;
  }
  .img-box {
    display: flex;
    width: var(--main-width);
    margin: auto;
    .skill-img-box {
      background-image: url("@/assets/home/homeImge2.png");
      background-size: 100% 100%;
      height: 380px;
      display: flex;
      align-items: center;
      padding-left: 40px;
      // flex: 1;
      // margin-right: 10px;
      &:nth-child(1) {
        flex: 1;
        margin-right: 10px;
      }
      &:nth-child(2) {
        width: 300px;
        margin-right: 10px;
        background-image: url("@/assets/home/homeImge3.png");
      }
      &:nth-child(3) {
        width: 300px;
        background-image: url("@/assets/home/homeImge4.png");
      }
      .text-box {
        .skill-title {
          font-size: 36px;
          font-weight: bold;
          line-height: 60px;
          color: #ffffff;
          margin-bottom: 10px;
        }
        .success-icon {
          width: 18px;
          height: 18px;
        }
        .skill-desc {
          font-size: 18px;
          line-height: 24px;
          color: #ffffff;
          margin-left: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .intro-img {
    width: 250px !important;
    height: 200px !important;
  }
  .ma-r-138 {
    margin-right: 50px;
  }
  .ma-l-138 {
    margin-left: 50px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1100px) {
  .intro-img {
    width: 250px !important;
    height: 200px !important;
  }
  .ma-r-138 {
    margin-right: 70px;
  }
  .ma-l-138 {
    margin-left: 70px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1500px) {
  .intro-img {
    width: 350px !important;
    height: 280px !important;
  }
  .ma-r-138 {
    margin-right: 70px;
  }
  .ma-l-138 {
    margin-left: 70px;
  }
}
// 媒体查询
@media screen and (max-width: 1400px) {
  .icon-item {
    margin-right: 20px !important;
    // img {
    //   width: 90px !important;
    //   height: 90px !important;
    // }
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .icon-item {
    margin-right: 40px !important;
  }
}
// 媒体查询
@media screen and (max-width: 1200px) {
  .product-tabs {
    width: 550px !important;
    padding: 20px 30px !important;
  }
}
// 媒体查询
@media screen and (max-width: 1400px) {
  .skill-img-box {
    height: 300px !important;
    &:nth-child(2) {
      width: 236px !important;
    }
    &:nth-child(3) {
      width: 236px !important;
    }
  }
}
</style>