import { createApp } from 'vue'
import './style.css'
import './style/common.less'
import 'ant-design-vue/dist/reset.css'
import App from './App.vue'
import Antd from 'ant-design-vue';
import axios from 'axios';

const app = createApp(App)
// 将 axios 挂载到 Vue 实例的原型上
app.config.globalProperties.$axios = axios;

app.use(Antd).mount('#app')
