<template>
  <div class="header-container-out">
    <div class="header-container">
      <div class="header-box">
        <img
          class="logo"
          src="@/assets/common/logo.svg"
          alt="柚子慧logo"
          width="80"
          height="80"
        />
        <div class="common-nav-container">
          <div
            :class="['nav-item', { active: activeKey === val.value }]"
            v-for="val in navList"
            :key="val.value"
            @click="() => change(val.value)"
          >
            {{ val.title }}
          </div>
        </div>
      </div>
      <div class="login-btn cursor" @click="goLogin">登录/注册</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch } from "vue";
const emit = defineEmits(["changePage"]);
const props = defineProps(["newActiveIndex"]);
const activeKey = ref(0);
const navList = [
  { title: "首页", value: 0 },
  { title: "解决方案", value: 1 },
  { title: "选择套餐", value: 2 },
  { title: "商务合作", value: 3 },
  { title: "关于我们", value: 4 },
];
const change = (value) => {
  activeKey.value = value;
  emit("changePage", activeKey.value);
};
const goLogin = () => {
  console.log(
    import.meta.env.VITE_MANAG_PATH,
    "import.meta.env.VITE_MANAG_PATH"
  );
  const newWindow = window.open(import.meta.env.VITE_MANAG_PATH);
  newWindow.opener = null;
};
watch(
  () => props.newActiveIndex,
  (newVal) => {
    activeKey.value = newVal;
  }
);
</script>
<style lang="less" scoped>
.header-container-out {
  width: 100%;
  display: flex;
  align-items: center;
}
.header-container {
  width: var(--main-width);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  .header-box {
    display: flex;
  }
}
.common-nav-container {
  margin-top: 23px;
}
.login-btn {
  width: 100px;
  height: 38px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 202px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff4f12;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .nav-item {
    padding: 10px !important;
  }
}
</style>


