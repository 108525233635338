<template>
  <!-- 和柚子慧一起，探索研学增长动能 -->
  <div class="contact-container">
    <div class="contact-title">和柚子慧一起，探索研学增长动能</div>
    <div class="desc">欢迎联系柚子慧营销达人专家，顾问将为您提供全力支持</div>
    <!-- <img src="@/assets/home/contactBtn.png" alt="联系我们" /> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.contact-container {
  width: 100%;
  height: 320px;
  background: url("@/assets/home/contactBg.png");
  background-size: 100% 100%;
  text-align: center;
  padding: 60px 0;
  .contact-title {
    font-size: 40px;
    line-height: 60px;
    color: #1d2129;
    margin-top: 40px;//按钮还原后删除该样式
  }
  .desc {
    font-size: 20px;
    line-height: 24px;
    color: #86909c;
    margin: 8px 0 48px;
  }
  img {
    width: 240px;
    height: 60px;
    border-radius: 8px;
    cursor: pointer;
  }
}
</style>