<template>
  <div class="footer-container">
    <div class="footer-top">
      <div class="footer-top-info">
        <div class="footer-top-left">
          <div class="list-item">
            <div class="title">柚子慧</div>
            <ul>
              <li v-for="item in list1" :key="item">{{ item.title }}</li>
            </ul>
          </div>
          <div class="list-item">
            <div class="title">服务优势</div>
            <ul>
              <li v-for="item in list2" :key="item">{{ item.title }}</li>
            </ul>
          </div>
          <div class="list-item">
            <div class="title">商务合作</div>
            <ul>
              <li v-for="item in list3" :key="item">{{ item.title }}</li>
            </ul>
          </div>
          <div class="list-item">
            <div class="title">商务合作</div>
            <ul>
              <li v-for="item in list4" :key="item">{{ item.title }}</li>
            </ul>
          </div>
          <div class="list-item">
            <div class="title">支持</div>
            <ul>
              <li v-for="item in list5" :key="item">{{ item.title }}</li>
            </ul>
          </div>
        </div>
        <div class="footer-top-right">
          <img
            src="@/assets/common/logoNoBg.svg"
            alt="柚子慧logo"
            class="logo"
          />
          <!-- <div class="text">
            商务合作电话：<span class="phone">023-6666 8888</span>
          </div> -->
          <div class="code-box">
            <div class="xiaochengxu-box">
              <img
                src="@/assets/common/xiaochengxu.svg"
                alt="关注柚子慧小程序"
                class="code"
              />
              <div class="text">
                关注柚子慧小程序
                <br />
                研学服务随时享
              </div>
            </div>
            <div>
              <img
                src="@/assets/common/gongzhonghao.svg"
                alt="关注柚子慧公众号"
                class="code"
              />
              <div class="text">
                关注柚子慧公众号
                <br />
                研学资讯随时查
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-bottom-info">
        <span class="footer-tips"
          ><span class="cursor">©2022-2023 重庆宗甫鲲林科技有限公司&emsp;</span>
          <span class="cursor">渝ICP备2024044265号 &emsp;&emsp;&emsp;</span>
          <span class="cursor">&emsp;法律声明</span>
          <span class="cursor">&emsp;隐私政策</span>
        </span>
        <!-- <span class="record-info"
          ><img src="@/assets/common/record.png" alt="record" />渝ICP 备
          09004390 号 - 2</span
        > -->
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, nextTick } from "vue";
const list1 = [
  { title: "关于我们", value: 0 },
  { title: "增值服务", value: 1 },
  // { title: "城市合伙人", value: 2 },
  // { title: "有什么新功能", value: 3 },
];
const list2 = [
  { title: "核心优势", value: 0 },
  { title: "比较优势", value: 1 },
  { title: "技术优势", value: 2 },
];
const list3 = [
  { title: "商家留资", value: 0 },
  { title: "申请入驻", value: 1 },
];
const list4 = [{ title: "新闻资讯", value: 0 }];
const list5 = [
  { title: "发送邮件", value: 0 },
  // { title: "微信公众号", value: 1 },
  // { title: "隐私政策", value: 2 },
];
</script>
<style lang="less" scoped>
.footer-container {
  width: 100%;
  background: #1c232e;
}
ul,
li {
  list-style: none;
  font-size: 16px;
  color: #939aa1;
  text-align: left;
  padding-left: 0;
}
.footer-top {
  .footer-top-info {
    width: 80%;
    // height: 420px;
    height: 350px;
    margin: auto;//解开商务电话样式后删除
    padding: 25px 0 110px;
    display: flex;
    justify-content: space-between;
    .footer-top-left {
      display: flex;
      .list-item {
        width: 120px;
        margin-right: 50px;
        &:last-child {
          margin-right: 0;
        }
        .title {
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 24px;
        }
        li {
          margin-bottom: 10px;
          cursor: pointer;
          color: #939aa1;
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .footer-top-right {
      color: #ffffff;
      .logo {
        width: 80px;
        height: 52px;
      }
      // .text {
      //   font-size: 16px;
      //   margin: 20px 0 40px;
      //   .phone {
      //     font-size: 24px;
      //   }
      // }
      .code-box {
        display: flex;
        margin-top: 40px;//解开商务电话样式后删除
        .xiaochengxu-box {
          margin-right: 70px;
          text-align: center;
        }
        .text {
          font-size: 12px;
          line-height: 22px;
          margin-top: 8px;
        }
      }
    }
  }
}
.footer-bottom {
  border-top: 1px solid #3d4756;
  font-size: 16px;
  .footer-bottom-info {
    width: 80%;
    padding: 25px 0;
    margin: auto;
    display: flex;
    color: #939aa1;
    // justify-content: space-between;
    justify-content: center ;
    .footer-tips {
      .cursor {
        &:hover {
          color: #fff;
        }
      }
    }
    .record-info {
      display: flex;
      align-items: center;
      padding-left: 12px;
      cursor: pointer;
      &:hover {
        color: #fff;
      }
      img {
        width: 19px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
}
// 媒体查询
@media screen and (max-width: 1200px) {
  .list-item {
    margin-right: 20px !important;
  }
}
@media screen and (max-width: 1100px) {
  .list-item {
    margin-right: 0 !important;
  }
}
</style>


