<template>
  <div class="package-container-out">
    <div class="package-header">
      <div class="package-header-info">
        <div class="header-title">柚子慧增值服务</div>
        <div class="header-dec">
          依托于柚子慧数字科技的服务专家团队及生态伙伴资源，我们为您提供包括咨询与设计、运维与保障、运营与优化等全生命周期服务，满足您业务不同阶段的服务需求
        </div>
      </div>
      <img
        src="@/assets/package/packageBg3.png"
        alt="header-img"
        class="header-img"
      />
    </div>
    <div class="package-container">
      <div class="standard-container package-item">
        <div class="package-top">
          <div class="title">试用版</div>
          <div class="desc">
            满足店铺搭建与内容变现需求<br />
            实现基础知识服务闭环
          </div>
        </div>
        <div class="package-info">
          <div class="free-img-box">
            <img
              src="@/assets/package/freeText.png"
              alt="免费"
              class="free-img"
            />
          </div>
          <div class="btn flex-center disabled">立即试用</div>
          <div class="intro-title">标准版包含以下服务：</div>
          <div class="content-item" v-for="item in servicelist" :key="item">
            <img
              src="@/assets/home/descIcon.png"
              :alt="item"
              class="desc-icon"
            />
            <div class="text">{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="premium-container package-item">
        <div class="package-top">
          <div class="title">高级版</div>
          <div class="desc">
            深度连接公域与私域<br />
            覆盖引流获客、私域运营、交易变现场景<br />
            实现完整知识服务与用户运营闭环
          </div>
        </div>
        <div class="package-info">
          <div class="package-title">
            ￥<span class="price">6800</span>
            / 年
          </div>
          <div class="btn flex-center" @click="apply">立即购买</div>
          <div class="intro-title">包含标准版服务及以下：</div>
          <div
            class="content-item"
            v-for="item in premiumServicelist"
            :key="item"
          >
            <img
              src="@/assets/home/descIcon.png"
              :alt="item"
              class="desc-icon"
            />
            <div class="text">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 和柚子慧一起，探索研学增长动能 -->
    <ContactFooter />
  </div>
</template>

<script lang="ts" setup>
import { ref, nextTick } from "vue";
import ContactFooter from "@/components/ContactFooter.vue";
const emit = defineEmits(["changeAcitve"]);
const apply = () => {
  emit("changeAcitve", 3);
};

const servicelist = [
  "产品管理",
  "店铺管理",
  "订单管理",
  "后台管理",
  "消息中心",
  "5*8小时服务",
];
const premiumServicelist = [
  "会员集中化管理及数据统计",
  "机构及店铺一站式管理",
  "研学产品设计与发布",
  "研学团期与订单联动管理",
  "订单财务流水情况统计",
  "自营店铺及申请加盟店铺审核",
  "线路资源管理系统",
  "会员运营工具",
  "专属服务团队",
  "由产品和技术专家解答",
  "技术服务经理定期为客户提供分析报告",
  "定期行业报告",
  "7*24小时服务",
];
</script>

<style lang="less" scoped>
.package-container-out {
  width: 100%;
}
.package-header {
  position: relative;
  width: 100%;
  .header-img {
    width: 100%;
  }
  .package-header-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .header-title {
      font-size: 56px;
      font-weight: bold;
      line-height: 80px;
      color: #ffffff;
      text-shadow: 0px 1px 1px #cb4213;
      margin-bottom: 16px;
    }
    .header-dec {
      font-size: 16px;
      line-height: 28px;
      color: #ffffff;
    }
  }
}
.package-container {
  display: flex;
  justify-content: center;
  width: var(--main-width);
  padding: 50px 0 85px;
  margin: auto;
}
.package-item {
  width: 400px;
  box-shadow: 0px 0px 10px #f1ecec;
  border-radius: 20px 20px 12px 12px;
  .package-top {
    width: 100%;
    height: 200px;
    padding: 24px 26px 48px;
    background-size: 100% 100%;
    .title {
      font-size: 36px;
      font-weight: bold;
      line-height: 60px;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .desc {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #ffffff;
    }
  }
  .package-info {
    padding: 24px 30px;
    .btn {
      width: 340px;
      height: 60px;
      border-radius: 12px;
      background: linear-gradient(270deg, #39b8f9 0%, #3c82fe 100%);
      font-size: 24px;
      font-weight: bold;
      line-height: 60px;
      color: #ffffff;
      margin: 40px 0 24px;
      cursor: pointer;
      &.disabled {
        background: #dfdfdf;
        font-weight: bold;
        line-height: 60px;
        color: #181818;
        cursor: not-allowed;
      }
    }
    .intro-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #181818;
      margin-bottom: 32px;
    }
    .content-item {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0px;
      }
      .desc-icon {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
        color: #86909c;
      }
    }
  }
  &.standard-container {
    margin-right: 80px;
    .package-top {
      background-size: 100% 100%;
      background-image: url("@/assets/package/packageBg1.png");
    }
    .free-img-box {
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }
    .free-img {
      width: 80px;
      height: 40px;
    }
  }
  &.premium-container {
    .package-top {
      background-image: url("@/assets/package/packageBg2.png");
    }
    .btn {
      background: linear-gradient(270deg, #ffb346 0%, #ff852d 100%);
    }
    .package-title {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: #000000;
      .price {
        font-size: 48px;
        font-weight: bold;
        line-height: 60px;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        background: linear-gradient(270deg, #ffb346 0%, #ff852d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
}
.standard-container {
  // margin-right: 80px;
}
.premium-container {
}
</style>