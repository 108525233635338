<template>
  <a-modal
    v-model:open="open"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelButtonProps="{ style: 'dispaly:none;' }"
  >
    <a-result status="success" title="您已提交店铺申请，请保持电话畅通">
    </a-result>
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk" class="ok-btn"
        >确认</a-button
      >
    </template>
  </a-modal>
</template>

<script lang="ts" setup>
import { ref } from "vue";
const emit = defineEmits(["closeModel"]);
const open = ref<boolean>(true);
const handleOk = () => {
  emit("closeModel");
};
const handleCancel = () => {
  emit("closeModel");
};
</script>

<style lang="less" scoped>
.ok-btn {
  border-radius: 8px;
  color: #ffffff;
  background: #ea4f13;
  &:hover {
    background: #ee400b;
  }
}
</style>>
